<section>
    <div class="animal-detail__main-content-container d-md-flex align-items-md-center mb-8">
        <div class="animal-detail__animal-image-wrapper order-md-2 d-flex align-items-md-center">
            <model-viewer class="animal-detail__animal-image" src="assets/images/animals/{{animal.name}}/glb.glb" camera-controls  alt="{{animal.name}}"></model-viewer>
        </div>
        <div class="animal-detail__animal-descriptor order-md-1 ml-md-3 ml-lg-5 mr-md-3">
            <div class="animal-detail__animal-heading-container">
                <!-- <h2 class="animal-detail__title skin__text-shadow text-center text-md-left">{{animal.name}}2</h2> -->
                <h2 class="animal-detail__title skin__text-shadow text-center text-md-left">{{'animal_' + animal.name +'_translated'  | translate}}</h2>
                <p class="animal-detail__subtitle text-center text-md-left skin__text-shadow">{{'animal_'+ animal.name + '__surname' | translate}}</p>
                <button (click)="routeAdjacentAnimal(animalAdjacents[0])" class="skin__carousel-arrow skin__carousel-arrow--left"><i class="icon-icon_arrow_left1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i></button>
                <button (click)="routeAdjacentAnimal(animalAdjacents[1])" class="skin__carousel-arrow skin__carousel-arrow--right"><i class="icon-icon_arrow_right1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i></button>
            </div>
            <p class="animal-detail__desc1">{{'animal_'+ animal.name + '__desc1' | translate}}</p>
            <p class="animal-detail__desc2 text-col-fjordgreen">{{'animal_'+ animal.name + '__desc2' | translate}}</p>
        </div>
    </div>
    <div class="d-md-flex align-items-md-center justify-content-md-end position-relative">
        <div class="d-flex flex-column flex-md-row align-items-center">
            <div class="animal-detail__meet-my-friends-descriptor order-md-1">
                <h4 class="animal-detail__friends-title text-center text-md-right skin__text-shadow">{{'animal_detail__friends_title' | translate}}</h4>
                <p class="animal-detail__friends-desc text-col-fjordgreen text-center text-md-right">{{'animal_detail__friends_desc1' | translate}} {{'animal_' + animal.name +'_translated'  | translate}} {{'animal_detail__friends_desc2' | translate}}
                    <ng-container *ngIf="animal.name == 'Billy' "> {{'animal_detail__friends_desc3' | translate}} </ng-container>
                    <ng-container *ngIf="animal.name == 'Bonny' "> {{'animal_detail__friends_desc3' | translate}} </ng-container>
                    <ng-container *ngIf="animal.name == 'Parker' "> {{'animal_detail__friends_desc3' | translate}} </ng-container>
                    <ng-container *ngIf="animal.name == 'Koko' "> {{'animal_detail__friends_desc3' | translate}} </ng-container>
                    <ng-container *ngIf="animal.name == 'Harry' "> {{'animal_detail__friends_desc3' | translate}} </ng-container>
                    <ng-container *ngIf="animal.name == 'Max' "> {{'animal_detail__friends_desc3' | translate}} </ng-container>
                    <ng-container *ngIf="animal.name == 'Fabrizio' "> {{'animal_detail__friends_desc3' | translate}} </ng-container>
                    <ng-container *ngIf="animal.name == 'Chloe' "> {{'animal_detail__friends_desc4' | translate}} </ng-container>
                    <ng-container *ngIf="animal.name == 'Frenchy' "> {{'animal_detail__friends_desc4' | translate}} </ng-container>
                    <ng-container *ngIf="animal.name == 'Olivia' "> {{'animal_detail__friends_desc4' | translate}} </ng-container>
                    <ng-container *ngIf="animal.name == 'Umma' "> {{'animal_detail__friends_desc4' | translate}} </ng-container>
                    <ng-container *ngIf="animal.name == 'Luca' "> {{'animal_detail__friends_desc4' | translate}} </ng-container>
                    
                </p>
            </div>
            <div class="skin__separator d-none d-md-inline-block mx-3 order-md-2"></div>
            <img class="animal-detail__meet-my-friends-image mb-2 mb-md-0" src="assets/images/animals/{{animal.name}}/friends.png">
            <ul class="animal-detail__friends-list d-flex order-md-3">
                <li *ngFor="let bestFriend of animal.bestFriends">
                    <button (click)="routeAdjacentAnimal(bestFriend)" class="animal-detail__button-friends-desktop skin__btn-opac-back d-none d-md-inline-flex flex-column align-items-center px-4 typo-14-B ">
                        <p class="typo-16-B text-center mb-1 skin__text-shadow">{{bestFriend | translate}}
                        </p>
                        <p class="typo-12-B text-center mb-2 mb-md-0 skin__text-shadow">{{'animal_'+ bestFriend + '__surname' | translate}}</p>
                    </button>    
                    <div class="d-md-none d-flex flex-column align-items-center">
                        <p class="typo-16-B text-center mb-0 skin__text-shadow">{{bestFriend}}</p>
                        <p class="typo-12-B text-center mb-2 mb-md-0 skin__text-shadow">{{'animal_'+ bestFriend + '__surname' | translate}}</p>
                        <button (click)="routeAdjacentAnimal(bestFriend)" class="skin__btn-white-back-opac-bord px-4 py-1 py-sm-2 typo-14-B">{{'animal__meet_me' | translate}}</button>
                    </div>
                </li>

            </ul>
        </div>
    </div>
</section>
