<main id="main-content" class="layout__main-container px-4">
    <div class="mb-0">
        <section>
            <app-logo-header class="d-block mb-7 mb-lg-8 d-xxl-none" logoType="text"></app-logo-header>
            <div class="d-flex justify-content-center position-relative">
                <img class="not-found__image" src="assets/images/404-background.png">
                <div class="not-found__header position-absolute">
                    <h1 class="not-found__title skin__text-stroke--title text-center">{{'not_found__title' | translate}}</h1>
                    <h2 class="not-found__subtitle skin__text-stroke--subtitle text-center">{{'not_found__subtitle' | translate}}</h2>
                </div>
            </div>

        </section>
    </div>
    <app-download-app-card-one></app-download-app-card-one>
    <app-mycollection-card></app-mycollection-card>
</main>