<section>
    <h3 class="animal-accessories__title text-center skin__text-shadow">{{'animal_accessories__title' | translate}}</h3>
    <p class="animal-accessories__description text-col-fjordgreen text-center">{{'animal_accessories__desc' | translate}}</p>
    <div class="animal-accessories__carousel mx-n4 mx-sm-0">
        <swiper></swiper>
        <div class="swiper-container" [swiper]="swipperConfig">
            <div class="swiper-wrapper pb-6">
                <app-animal-accessory class="swiper-slide" [animalName]=animalName [animalAccesoryIndex]=i+1 *ngFor="let n of accesoriesIterator(); let i = index"></app-animal-accessory>
            </div>
            <div class="swiper-pagination skin__carousel-pagination animal-accessories__pagination"></div>
        </div>
    </div>
    <div class="animal-accessories__grid">
        <app-animal-accessory [animalName]=animalName [animalAccesoryIndex]=i+1 *ngFor="let n of accesoriesIterator(); let i = index"></app-animal-accessory>
    </div>
</section>

