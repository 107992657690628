<div>
    <header class="welcome-country__header align-items-center justify-content-center d-none d-sm-flex">
        <img class="welcome-country__chupachups-logo" src="assets/images/chupachups-logo-with-text.svg" alt="Chupa Chups Logo"/>
    </header>
    <div class="welcome-contry__content-wrapper d-flex flex-column align-items-center p-4">
        <h1 class="text-center"><img class="welcome-country__mylovelyanimals-logo" src="assets/images/mylovelyanimals-text.svg" alt="My Lovely Animals"/></h1>
        <h2 class="welcome-country__subtitle text-center mt-4 mb-0 skin__text-shadow">Welcome To My Lovely Animals</h2>
        <p class="welcome-country__description text-center mt-2 text-col-fjordgreen mb-4">Select your country</p>
         <div class="welcome-country__input input-select custom-select">
            <select>
                <option value="0">Country</option>
                <option value="1">Italy</option>
                <option value="2">Russia</option>
            </select>
        </div>
        <div class=" welcome__planet-container position-relative">
            <img class="welcome-country__animals-chorus" src="assets/images/animals-chorus.svg" alt="Chupa Chups Logo"/>
            <img class="welcome-country__planet position-absolute" src="assets/images/planet.svg" alt="Chupa Chups Logo"/>
            <a [routerLink]="['/home']"><button  class="welcome-country__access skin__btn-white-back-opac-bord position-absolute">Access</button></a>
        </div>
    </div>
    
</div>

