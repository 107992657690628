<section id="print-section">
    <h2 class="animals-card-list-mycollection__title text-center skin__text-shadow mb-0">{{'mycollection_title' | translate}}</h2>
    <div>
        <p *ngIf="((activeFilter === 'animalsMissing') && (animalsMissing == 12)) ||  ((activeFilter === 'animalsObtained') && (animalsObtained == 0))" class="animals-card-list-mycollection__description">{{'mycollection_result_message_empty' | translate}}</p>
        <p *ngIf="(activeFilter === 'animalsFavourite') && (animalsFavourite == 0)" class="animals-card-list-mycollection__description">{{'mycollection_result_message_no_favourites' | translate}}</p>
        <p *ngIf="(activeFilter !== 'animalsFavourite') && (animalsMissing == 0) && (animalsObtained == 12)" class="animals-card-list-mycollection__description">{{'mycollection_result_message_complete' | translate}}</p>
    </div>
    <section *ngIf="isVisibleMyCollectionAnimals()" class="animals-cards-list mt-5 mt-sm-8 animate__animated animate__zoomIn">
        <app-animal-card-mycollection  *ngFor="let animal of animals | keyvalue: originalOrder" [animal] = animal></app-animal-card-mycollection>
    </section>
</section>    
