import { NgZone } from '@angular/core';
import { Component, Input, OnInit, AfterViewChecked } from '@angular/core';

@Component({
  selector: 'app-animal-card-meetme',
  templateUrl: './animal-card-meetme.component.html',
  styleUrls: ['../animals-cards.component.scss']
})
export class AnimalCardMeetmeComponent implements OnInit {

  @Input() animal: any;

  constructor(private ngZone: NgZone) { }

  ngOnInit(){}

  /*ngAfterViewChecked(){
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        let animalImageHeight: any = $('.animal-card__animal-image--lolypop').height();
        if(animalImageHeight > 0 ) {
          $(".animal-card__animal-image--lolypop-spacer").height(animalImageHeight/2);
          $(".animal-card__image-spacer").css('padding-top', animalImageHeight/2);
        } 
      });
    });
  }*/

}
