<section class="contact-form animate__animated animate__zoomIn">
    <h1 class="contact-form__title skin__text-stroke--title text-center mb-5">{{'contact__title' | translate}}</h1>
    <p class="contact-form__description text-col-fjordgreen text-center">{{'contact__desc' | translate}}</p>
    <form #form class="contact-form__form d-flex flex-column flex-md-row align-items-start justify-content-md-between m-auto" onsubmit="return false">
        <div class="contact-form__form-column d-flex flex-column justify-content-start">
            <input type="text" name="name" class="skin__input-opac-back-white-border py-2 mb-4" placeholder="{{'form__name' | translate}}">
            <input type="email" name="email" class="skin__input-opac-back-white-border py-2 mb-4" placeholder="{{'form__email' | translate}}">
            <textarea type="text" name="message" class="contact-form__textarea skin__input-opac-back-white-border pb-2 mb-4 mb-md-0" placeholder="{{'form__message' | translate}}"></textarea>
        </div>
        <div class="contact-form__form-column d-flex flex-column">
            <input type="text" name="surname" class="skin__input-opac-back-white-border py-2 mb-4" placeholder="{{'form_email_subject' | translate}}">
            <!-- <input type="text" name="phone" class="skin__input-opac-back-white-border py-2" type="text" placeholder="{{'form__phone' | translate}}"> -->
            <p [innerHTML]="'form__legal_text' | translate">

            </p>
            <label class="contact-form__checkbox skin__check-opac-back-white-border typo-13-Q text-col-dust">
                <input type="checkbox" name="accept_legal_conditions">
                <span><i class="icon-icon_check1--1"></i></span>
                {{'form__i_accept_legal_conditions' | translate}}
            </label>
            <button (click)="contact()" class="contact-form__submit-button skin__btn-white-back-opac-bord align-self-start px-8 py-2">{{'form__send' | translate}}</button> 
            <div id="form-messages" class="form-messages"></div>
        </div>
    </form>
</section>
