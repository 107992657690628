import { Injectable } from "@angular/core";
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ModalAboutComponent } from '../../app/partials/modal-about/modal-about.component';

@Injectable({ providedIn: 'root'})
export class ModalService {

    public activeModal: string;
    
    constructor(private _ngModal: NgbModal) {}

    public openModal(modalDisplayed: string): void {
        this._ngModal.open(ModalAboutComponent);
        this.activeModal = modalDisplayed;
    }

}