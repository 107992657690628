import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
declare const cardHover: any;

@Component({
  selector: 'app-download-app-card-two',
  templateUrl: './download-app-card-two.component.html',
  styleUrls: ['../download-app-cards.component.scss']
})
export class DownloadAppCardTwoComponent implements OnInit, AfterViewInit {

  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {}

  ngAfterViewInit(){
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        cardHover(".download-app-card--two");
      });
    });
 }

}
