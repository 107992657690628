<div class="mycollection-card__image-spacer skin__card-hover-effect-wrapper">
    <section class="skin__card-hover-effect mycollection-card d-flex flex-column align-items-center flex-md-row justify-content-md-between py-6 py-lg-5 px-5 px-lg-6 skin__round-40 skin__object-shadow--big skin__tropial-ocean-gradient">
        <img class="mycollection-card__animals-image mb-4 mb-md-0" src="assets/images/my-animals-colecctions.png" alt="My Animals Collection"/>
        <div class="mycollection-card__descriptor d-flex flex-column align-items-center align-items-center align-items-md-start">
            <h3 class="mycollection-card__title text-col-lavender text-center">{{'global__mycollection' | translate}}</h3>
            <p class="mycollection-card__description text-col-dust text-center text-md-left">{{'mycollection_card__desc' | translate}}</p>
            <button [routerLink]="['/mycollection']" class="mycollection-card__play-button skin__btn-white-back-opac-bord py-2 text-center">{{'mycollection_card__lets_play' | translate}}</button>
        </div>
    </section>
</div>

