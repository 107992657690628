<section class="mycollection-header pt-0">
    <div class="mycollection-header__background-wrapper position-absolute "  [ngClass]="'mycollection-header__background--type-' + randomBackgroundIndex">
        <div class="mycollection-header__content-wrapper position-relative d-flex align-items-end justify-content-center justify-content-lg-end">
            <section class="mycollection-header__logo-wrapper position-absolute d-flex align-items-center justify-content-center">
                <h1>
                    <a [routerLink]="['/home']">
                        <img class="mycollection-header__logo" src="assets/images/mylovelyanimals.svg" alt="My Lovely Animals"/>
                        <p style="font-size: 11px; text-align: center; margin-top: 5px;">*Мои Милые Животные</p>
                    </a>
                </h1>
            </section>
        </div>
    </div>
</section>