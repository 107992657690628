import { Component, Input, OnInit } from '@angular/core';
import { AnimalsService } from 'ts/services/animals.service';

@Component({
  selector: 'app-animal-card-mycollection',
  templateUrl: './animal-card-mycollection.component.html',
  styleUrls: ['../animals-cards.component.scss']
})
export class AnimalCardMycollectionComponent implements OnInit {

  @Input() animal: any;
  
  constructor(public animalsService: AnimalsService) { }

  ngOnInit(): void {
  }

}
