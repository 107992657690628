<section class="mycollection_filter_count">
    <div (click)="animalsService.animalsFilterResults(this.constants.ANIMALS_FILTER_OBTAINED)" class="d-flex flex-column align-items-center flex-xxl-row align-items-md-center mr-sm-4 mr-lg-5">
        <div class="mycollection_filter_count__bullet d-flex align-items-center justify-content-center mb-3 mb-xxl-0 mr-xxl-3 position-relative">
            <p class="mb-0 skin__text-shadow">{{this.animalsObtained}}</p>
            <i class="mycollection_filter_count__bullet-icon icon-icon_check1 position-absolute"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
        </div>
        <button [ngClass]="(animalsService.activeFilter==this.constants.ANIMALS_FILTER_OBTAINED)?'mycollection_filter_count__button--active':''" class="mycollection_filter_count__button skin__btn-opac-back py-2">{{'mycollection_filter_count__my_animals_in_album' | translate}}</button> 
    </div>
    <div (click)="animalsService.animalsFilterResults(this.constants.ANIMALS_FILTER_FAVOURITE)" class=" d-flex flex-column align-items-center flex-xxl-row align-items-md-center mr-sm-4 mr-lg-5">
        <div class="mycollection_filter_count__bullet d-flex align-items-center justify-content-center mb-3 mb-xxl-0 mr-xxl-3 position-relative">
            <p class="mb-0 skin__text-shadow">{{this.animalsFavourite}}</p>
            <i class="mycollection_filter_count__bullet-icon icon-Icon_favorites_small position-absolute"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
        </div>
        <button [ngClass]="(animalsService.activeFilter==this.constants.ANIMALS_FILTER_FAVOURITE)?'mycollection_filter_count__button--active':''" class="mycollection_filter_count__button skin__btn-opac-back py-2">{{'mycollection_filter_count__favorites' | translate}}</button> 
    </div>
    <div (click)="animalsService.animalsFilterResults(this.constants.ANIMALS_FILTER_MISSING)" class=" d-flex flex-column align-items-center flex-xxl-row align-items-md-center mr-sm-4 mr-lg-5">
        <div class="mycollection_filter_count__bullet d-flex align-items-center justify-content-center mb-3 mb-xxl-0 mr-xxl-3 position-relative">
            <p class="mb-0 skin__text-shadow">{{this.animalsMissing}}</p>
            <i class="mycollection_filter_count__bullet-icon icon-Icon_missing position-absolute"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
        </div>
        <button [ngClass]="(animalsService.activeFilter==this.constants.ANIMALS_FILTER_MISSING)?'mycollection_filter_count__button--active':''" class="mycollection_filter_count__button skin__btn-opac-back py-2">{{'mycollection_filter_count__missing_animals' | translate}}</button> 
    </div>
    <button class="mycollection_filter_count__print d-flex flex-column align-items-center flex-xxl-row"
        [useExistingCss]="true" 
        printSectionId="print-section" 
        ngxPrint>
        <span class="mycollection_filter_count__print-description mr-xxl-3 order-2 order-xxl-2">{{'mycollection_filter_count__print_mycollection' | translate}}</span>
        <i class="mycollection_filter_count__print-icon icon-icon_print ico-24 skin__object-shadow--small order-1 order-xxl-1 mb-3 mb-xxl-0"></i>
    </button> 

</section>
