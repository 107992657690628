<div>
    <header class="parental__header align-items-center justify-content-center">
        <img class="parental__chupachups-logo" src="assets/images/chupachups-logo-with-text.svg" alt="Chupa Chups Logo"/>
    </header>
    <div *ngIf="true" class="container mt-lg-5 pt-lg-5">
        <div class="d-flex flex-column align-items-center mt-lg-5 pt-lg-5 pt-5">
            <h3>Мои Милые Животные отдыхают и больше недоступны</h3>
        </div>
    </div>
    <div *ngIf="false" class="container mt-lg-5 pt-lg-5">
        <div class="d-flex flex-column align-items-center mt-lg-5 pt-lg-5 pt-5">
            <h3>{{'parental__title' | translate}}</h3>
            
            <p class="pt-2" style="text-align: center;">{{'parental__description' | translate}}</p>
            
            <p style="text-align: center;">{{'parental__description2' | translate}}</p>
        </div>
        <div class="d-flex align-items-center parental-container mt-lg-5 pt-lg-5 pt-1">
            <!-- <div class="container"> -->
            <div class=""><a  (click)="modalService.openModal(constants.MODAL_PRIVACY_POLICY)" class="d-flex mt-lg-3 mt-3"><button class="skin__btn-white-back-opac-bord px-7 py-2 typo-15-B">{{'global__privacy_policy' | translate}}</button></a></div>
            <div class=""><a  (click)="modalService.openModal(constants.MODAL_COOKIES_POLICY)" class="d-flex mt-lg-3 mt-3"><button class="skin__btn-white-back-opac-bord px-7 py-2 typo-15-B ">{{'global__cookies_policy' | translate}}</button></a></div>
            <div class=""><a  (click)="modalService.openModal(constants.MODAL_LEGAL_NOTICE)" class="d-flex mt-lg-3 mt-3"><button class="skin__btn-white-back-opac-bord px-7 py-2 typo-15-B ">{{'global__legal_notice' | translate}}</button></a></div>
        </div>
        <div class="d-flex flex-column align-items-center parental-container">
            <a [routerLink]="'/home'" class="d-flex mt-lg-3 pt-1"><button class="animal-carousel__button skin__btn-opac-back px-3 py-2 ">{{'global__toaccept' | translate}}</button></a>
        </div>
        
    </div>
    
</div>
