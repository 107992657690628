import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { DownloadAppComponent } from './pages/download-app/download-app.component';
import { MeetTheLovelyanimalsComponent } from './pages/meet-the-lovelyanimals/meet-the-lovelyanimals.component';
import { MeetTheAnimalComponent } from './pages/meet-the-animal/meet-the-animal.component';
import { MycollectionComponent } from './pages/mycollection/mycollection.component';
import { ContactComponent } from './pages/contact/contact.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { WelcomeCountryComponent } from './pages/welcome-country/welcome-country.component';
import { ParentalComponent } from './pages/parental/parental.component';
import { PoliticaPrivacidadComponent } from './pages/politica-privacidad/politica-privacidad.component';
import { CookiePrivacyComponent } from './pages/cookie-privacy/cookie-privacy.component';
import { LegalNoticeComponent } from './pages/legal-notice/legal-notice.component';

import { ITguard } from 'ts/services/it-guard.service';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'parental',
    pathMatch: 'full'
  },
  {
    path: 'parental',
    component: ParentalComponent,
  },
  {
    path: 'welcome',
    component: WelcomeCountryComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'download-app',
    component: DownloadAppComponent,
  },
  {
    path: 'meet-the-lovelyanimals',
    component: MeetTheLovelyanimalsComponent,
  },
  {
    path: 'meet-the-animal/:animalName',
    component: MeetTheAnimalComponent,
  },
  {
    path: 'mycollection',
    component: MycollectionComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate:[ITguard]
  },
  {
    path: 'faqs', 
    component: FaqsComponent
  },
  {
    path: 'privacy-policy', 
    component: PoliticaPrivacidadComponent
  },  
  {
    path: 'cookies-policy', 
    component: CookiePrivacyComponent
  },  
  {
    path: 'legal-notice', 
    component: LegalNoticeComponent
  },  
  {
    path: '404', 
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { } 




/* const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  scrollOffset: [0, 64] // [x, y]
};



@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }*/