<section>
    <app-logo-header class="d-block mb-5 mb-sm-0" logoType="text"></app-logo-header>
    <div class="animate__animated animate__zoomIn">
        <h1 class="faqs-quests__title skin__text-stroke--title text-center">{{'faqs__title' | translate}}</h1>
        <h2 class="faqs-quests__subtitle skin__text-stroke--subtitle text-center">{{'faqs__subtitle' | translate}}</h2>
        <div class="faqs-quests__faqs-wrapper m-auto">
            <div class="faqs-quests__separator skin__object-shadow--small"></div>
            <div class="faqs-quests__faqs-list">
                <ngb-accordion class="faqs-quests__faqs-column" [destroyOnHide]='false' [closeOthers]="true" activeIds="">
                    <ngb-panel id="{{'panel' + (i+1)}}" *ngFor="let n of faqsIterator(col1); let i = index">
                    <ng-template ngbPanelTitle>
                            <p class="faqs-quests__question d-flex align-items-start justify-content-between text-left mb-0 skin__text-shadow">
                                {{'faqs__general_question_' + (i+1) | translate}}
                                <span class="ml-2">
                                    <i class="faqs-quests__arrow-close icon-icon_arrow_down_faqs ico-40 skin__text-shadow"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                                    <i class="faqs-quests__arrow-open icon-icon_arrow ico-40 skin__text-shadow"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                                </span>
                            </p>
                    </ng-template>
                    <ng-template ngbPanelContent>
                            <div class="faqs-quests__answer p-4 mt-4" [innerHTML]="'faqs__general_response_' + (i+1) | translate"></div>
                    </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <ngb-accordion class="faqs-quests__faqs-column" [destroyOnHide]='false' [closeOthers]="true" activeIds="">
                    <ngb-panel id="{{'panel' + (i+1+col1)}}" *ngFor="let n of faqsIterator(col2); let i = index">
                    <ng-template ngbPanelTitle>
                            <p class="typo-16-B d-flex align-items-start justify-content-between text-left mb-0 skin__text-shadow">
                                {{'faqs__general_question_' + (i+1+col1) | translate}}
                                <span class="ml-2">
                                    <i class="faqs-quests__arrow-close icon-icon_arrow_down_faqs ico-40 skin__text-shadow"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
                                    <i class="faqs-quests__arrow-open icon-icon_arrow ico-40 skin__text-shadow"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                                </span>
                            </p>
                    </ng-template>
                    <ng-template ngbPanelContent>
                            <div class="faqs-quests__answer p-3 mt-4" [innerHTML]="'faqs__general_response_' + (i+1+col1) | translate"></div>
                    </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
            <div class="faqs-quests__separator skin__object-shadow--small mt-7"></div>
        </div>
    </div>
</section>