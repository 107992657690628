import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'ts/services/modal.service';

@Component({
  selector: 'app-modal-about',
  templateUrl: './modal-about.component.html',
  styleUrls: ['./modal-about.component.scss']
})
export class ModalAboutComponent implements OnInit {

  public modalDisplayed: string;

  constructor(public activeModal: NgbActiveModal,
              private _modalService: ModalService) {}
  
  ngOnInit(): void {
    this.modalDisplayed = this._modalService.activeModal;
  }

}