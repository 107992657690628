import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mycollection-header',
  templateUrl: './mycollection-header.component.html',
  styleUrls: ['./mycollection-header.component.scss']
})
export class MycollectionHeaderComponent implements OnInit {

  public randomBackgroundIndex: number;

  constructor() { }

  ngOnInit(): void {
    this.randomBackgroundIndex = Math.floor(Math.random() * 3) + 1;
  }

}
