import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AnimalsService } from 'ts/services/animals.service';

@Component({
  selector: 'app-meet-the-animal',
  templateUrl: './meet-the-animal.component.html',
  styleUrls: ['./meet-the-animal.component.scss']
})
export class MeetTheAnimalComponent implements OnInit {
  
  private _animalGuessName: string;
  private _animals: any;
  public animal: any;
  public animalAdjacents: string[];

  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _animalsService: AnimalsService) {
    this._route.params.subscribe( params => this._animalGuessName = params['animalName'] );
  }
  
  ngOnInit(): void {
    this._animals = this._animalsService.animals;
    this.animal = this._getAnimal();
    this.animalAdjacents = this._getAnimalAdjacents();
  }

  private _getAnimal(): any {
    for (var animal of this._animals) {
      if (animal.name === this._animalGuessName) return animal;
    }
    return this._router.navigate(['/home']);
  }

  private _getAnimalAdjacents(): any {
    let lastAnimalIndex = Object.keys(this._animals).length - 1,
        currentIndex = 0;
    for (var animal of this._animals) {
      if (animal.name === this._animalGuessName)
        if((currentIndex == 0)) return [this._animals[lastAnimalIndex].name, this._animals[1].name];
        else if (currentIndex == lastAnimalIndex) return this.animalAdjacents = [this._animals[lastAnimalIndex - 1].name, this._animals[0].name];
        else return [this._animals[currentIndex - 1].name, this._animals[currentIndex + 1].name];
      currentIndex++;
    }
    return this._router.navigate(['/home']);
  }
}
