import { Injectable } from "@angular/core";

@Injectable()
export class LocalStorageService {

    public setItemStorage(name: string, value: any): void {
        localStorage.setItem(name, value);
    }

    public getItemStorageValue(name: string): string {
        return localStorage.getItem(name);
    }

    public deleteItemStorage(name: string): void {
        localStorage.removeItem(name);
    }

    public checkExistItemStorage(name: string): boolean {
        if (localStorage.getItem(name) === null) return false;
        return true;
    }

}