import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meet-the-lovelyanimals',
  templateUrl: './meet-the-lovelyanimals.component.html',
  styleUrls: ['./meet-the-lovelyanimals.component.scss']
})
export class MeetTheLovelyanimalsComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {}

}
