import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { appConfig } from 'ts/app.config';
import { TranslationService } from 'ts/services/translation/translation.service';


function customSelect() {
  var x, i, j, l, ll, selElmnt, a, b, c;
  /*look for any elements with the class "custom-select":*/
  x = document.getElementsByClassName("custom-select");
  l = x.length;
  for (i = 0; i < l; i++) {
  selElmnt = x[i].getElementsByTagName("select")[0];
  ll = selElmnt.length;
  /*for each element, create a new DIV that will act as the selected item:*/
  a = document.createElement("DIV");
  a.setAttribute("class", "select-selected");
  a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
  x[i].appendChild(a);
  //for earch element, create a new I Arrow
  var e = document.createElement('i');
  document.querySelector(".select-selected").appendChild(e);
  /*for each element, create a new DIV that will contain the option list:*/
  b = document.createElement("DIV");
  b.setAttribute("class", "select-items select-hide");
  for (j = 1; j < ll; j++) {
      /*for each option in the original select element,
      create a new DIV that will act as an option item:*/
      c = document.createElement("DIV");
      c.innerHTML = selElmnt.options[j].innerHTML;
      c.addEventListener("click", function(e) {
          /*when an item is clicked, update the original select box,
          and the selected item:*/
          var y, i, k, s, h, sl, yl;
          s = this.parentNode.parentNode.getElementsByTagName("select")[0];
          sl = s.length;
          h = this.parentNode.previousSibling;
          for (i = 0; i < sl; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName("same-as-selected");
              yl = y.length;
              for (k = 0; k < yl; k++) {
              y[k].removeAttribute("class");
              }
              this.setAttribute("class", "same-as-selected");
              break;
          }
          
          }
          h.click();
          e = document.createElement('i');
          document.querySelector(".select-selected").appendChild(e);
      });
      b.appendChild(c);
  }
  x[i].appendChild(b);
  a.addEventListener("click", function(e) {
      /*when the select box is clicked, close any other select boxes,
      and open/close the current select box:*/
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
      });
  }
  function closeAllSelect(elmnt) {
  /*a function that will close all select boxes in the document,
  except the current select box:*/
  var x, y, i, xl, yl, arrNo = [];
  x = document.getElementsByClassName("select-items");
  y = document.getElementsByClassName("select-selected");
  xl = x.length;
  yl = y.length;
  for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
      arrNo.push(i)
      } else {
      y[i].classList.remove("select-arrow-active");
      }
  }
  for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
      x[i].classList.add("select-hide");
      }
  }
  }
  /*if the user clicks anywhere outside the select box,
  then close all select boxes:*/
  document.addEventListener("click", closeAllSelect);
}

@Component({
  selector: 'app-welcome-country',
  templateUrl: './welcome-country.component.html',
  styleUrls: ['./welcome-country.component.scss']
})
export class WelcomeCountryComponent implements OnInit {

  selectedCountry: string;

  public appConfig: typeof appConfig = appConfig;

  constructor(public translationService: TranslationService,
              private _translate: TranslateService) {
                
               }

  ngOnInit(): void {
    customSelect()
  }
  

  changeLocation(selectedCountry) {
    if(selectedCountry == 'Russia') {
      appConfig.location = "RU";
      this.translationService.setLanguage("RU");
      console.log(this._translate.getLangs());
      this._translate.addLangs(["RU", "EN"]);
      console.log(this._translate.getLangs());
    } else {
      appConfig.location = "IT";
      this.translationService.setLanguage("IT");
      this._translate.addLangs(["IT", "EN"]);
    }

    console.log(appConfig.location);
  }

}
