import { Component, OnInit, ViewChild,ElementRef,ViewChildren,QueryList } from '@angular/core';
import { AnimalsService } from 'ts/services/animals.service';

@Component({
  selector: 'app-discover-animals',
  templateUrl: './discover-animals.component.html',
  styleUrls: ['./discover-animals.component.scss'],
  host: {
    '(window:resize)': 'onResize()',
  }
})
export class DiscoverAnimalsComponent implements OnInit {
  
  public animals: any;

  //Slider attributes
  @ViewChild('carousel') carousel: ElementRef;
  @ViewChildren('carousel__slide') cells: QueryList<ElementRef>;
  private _selectedIndex: number = 0;
  private _cellWidth: number;
  private _cellHeight: number;
  private _isHorizontal: boolean = true;
  private _rotateFn: string = 'rotateY';
  private _radius: number;
  private _theta: number;
  private _cellCount: number;
  private _angle: number;

  constructor(private _animalsService: AnimalsService) {}

  ngOnInit(): void {
    this.animals = this._animalsService.animals;
    setTimeout(() => {
      this._cellCount = this.cells?this.cells.length:0;
      this._cellWidth = this.carousel.nativeElement.offsetWidth;
      this._cellHeight = this.carousel.nativeElement.offsetHeight;
      this._initCarousel();
    });
  }

  public onResize(): void{
    this._cellWidth = this.carousel.nativeElement.offsetWidth;
    this._cellHeight = this.carousel.nativeElement.offsetHeight;
    this._initCarousel();
  }

  private _initCarousel() {
    this._theta = 360 / this._cellCount;
    this._radius = Math.round( ( this._cellWidth / 2) / Math.tan( Math.PI / this._cellCount ) );
    this.cells.forEach((cell:ElementRef,i:number)=>
    {
       if (i<this._cellCount)
       {
           const cellAngle=this._theta*i;
           cell.nativeElement.style.transform = this._rotateFn + '(' + cellAngle + 'deg) translateZ(' + this._radius + 'px)';
       }
       else
       {
        cell.nativeElement.style.transform = 'none';

       }
    })
    this._rotateCarousel();
  }

  private _rotateCarousel(): void {
    this._angle = this._selectedIndex / this._cellCount * -360;
    this.carousel.nativeElement.style.transform = 'translateZ(-'+(this._cellWidth*2)+'px)'+ this._rotateFn+'(' + this._angle + 'deg) rotateX(-0deg)';
    /* this.carousel.nativeElement.style.transform = 'translate(-'+(480)+'px)'+ this._rotateFn+'(' + this._angle + 'deg) rotateX(-0deg)'; */
    /* this.carousel.nativeElement.style.transform = 'translate(-'+(280)+'px)'; */
    this._activeSlideClass();
  }
  
  public prevSlide(): void {
    this._selectedIndex--;
    this._rotateCarousel();
  }

  public nextSlide(): void {
    this._selectedIndex++;
    this._rotateCarousel();
  }

  private _activeSlideClass(): void {
    this._clearActiveSlideClass();
    let nextIndex: number = this._calculateSelectedIndex();
    $(".discover-animals__slide[data-index=" + nextIndex +"]").addClass('discover-animals__active-slide discover-animals__active-slide--with-text');
    if(nextIndex == 0) {
      $(".discover-animals__slide[data-index=" + 11 +"]").addClass('discover-animals__active-slide');
      $(".discover-animals__slide[data-index=" + 1 +"]").addClass('discover-animals__active-slide');

      //$(".discover-animals__slide[data-index=" + 10 +"]").addClass('discover-animals__hide-slide');
      //$(".discover-animals__slide[data-index=" + 2 +"]").addClass('discover-animals__hide-slide');

    } else if(nextIndex == 1) {
      $(".discover-animals__slide[data-index=" + 0 +"]").addClass('discover-animals__active-slide');
      $(".discover-animals__slide[data-index=" + 2 +"]").addClass('discover-animals__active-slide');

      //$(".discover-animals__slide[data-index=" + 11 +"]").addClass('discover-animals__hide-slide');
      //$(".discover-animals__slide[data-index=" + 3 +"]").addClass('discover-animals__hide-slide');
    
    } else if(nextIndex == 10) {
      $(".discover-animals__slide[data-index=" + 9 +"]").addClass('discover-animals__active-slide');
      $(".discover-animals__slide[data-index=" + 11 +"]").addClass('discover-animals__active-slide');

      //$(".discover-animals__slide[data-index=" + 8 +"]").addClass('discover-animals__hide-slide');
      //$(".discover-animals__slide[data-index=" + 0 +"]").addClass('discover-animals__hide-slide');

    } else if(nextIndex == 11) {
      $(".discover-animals__slide[data-index=" + 10 +"]").addClass('discover-animals__active-slide');
      $(".discover-animals__slide[data-index=" + 0 +"]").addClass('discover-animals__active-slide');

      //$(".discover-animals__slide[data-index=" + 9 +"]").addClass('discover-animals__hide-slide');
      //$(".discover-animals__slide[data-index=" + 1 +"]").addClass('discover-animals__hide-slide');

    } else {
      $(".discover-animals__slide[data-index=" + (nextIndex - 1) +"]").addClass('discover-animals__active-slide');
      $(".discover-animals__slide[data-index=" + (nextIndex + 1) +"]").addClass('discover-animals__active-slide');

      //$(".discover-animals__slide[data-index=" + (nextIndex - 2) +"]").addClass('discover-animals__hide-slide');
      //$(".discover-animals__slide[data-index=" + (nextIndex + 2) +"]").addClass('discover-animals__hide-slide');
    }
  }

  private _clearActiveSlideClass(): void {
    for (var cell of this.cells) {
        cell.nativeElement.classList.remove('discover-animals__active-slide');
        cell.nativeElement.classList.remove('discover-animals__active-slide--with-text');
        cell.nativeElement.classList.remove('discover-animals__hide-slide');
    }
  }

  private _calculateSelectedIndex(): number {
    let totalSpins: number = (Math.trunc(Math.abs(this._angle/360))),
        currentAngleOneSpin: number = Math.abs(this._angle) - totalSpins*360,
        slideAngleIncrease: number = 360 / this._cellCount;
    if((Math.sign(this._angle) == -1) && !Math.sign(-0))  return currentAngleOneSpin / slideAngleIncrease;
    if(this._angle == 0 ) return 0
    return (360 - currentAngleOneSpin) / slideAngleIncrease
    
  }

}

