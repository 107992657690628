import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ContactService } from 'ts/services/contact.service';
import { FormService } from 'ts/services/form.service';
import { ContactModel } from 'ts/models/contact.model';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  providers: [FormService]
})
export class ContactFormComponent implements OnInit, AfterViewInit {
 
  @ViewChild("form", {read: ElementRef}) _form: ElementRef;

  private _elementForm: HTMLElement;
  public receiverOption: number = 1;
  public readonly receiverOptions = {
    CONTACT_CONSUMER: 1,
    CONTACT_DISTRIBUTOR: 2,
  }
  
  constructor(private _contactService: ContactService,
              private _formService: FormService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this._elementForm = this._form.nativeElement;
    this._formService.initFormInputLenght(this._elementForm);
  }
  
  public contact(): void {
    if(this._formService.formValidation()) {
      let contact: ContactModel = new ContactModel({
        name: String($(this._elementForm.querySelector('input[name="name"]')).val()),
        surname: String($(this._elementForm.querySelector('input[name="surname"]')).val()),
        email: String($(this._elementForm.querySelector('input[name="email"]')).val()),
        phone: Number($(this._elementForm.querySelector('input[name="phone"]')).val()),
        message: String($(this._elementForm.querySelector('input[name="message"]')).val()),
        receiver: this.receiverOption
      });
      this._contactService.contact(contact);
    }
  }

}
