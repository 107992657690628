import { Component, OnInit } from '@angular/core';
import { ModalService } from 'ts/services/modal.service';
import { constants } from 'ts/constants';
import { appConfig } from 'ts/app.config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  
  public readonly constants: typeof constants = constants;
  public appConfig: typeof appConfig = appConfig;

  constructor(public modalService: ModalService) {}

  ngOnInit(): void {}

  

}
