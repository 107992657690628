import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-lets-play',
  templateUrl: './lets-play.component.html',
  styleUrls: ['./lets-play.component.scss'],
  host: {
    '(window:resize)': 'onResize()',
  }
})
export class LetsPlayComponent implements OnInit {

  public swipperConfig: SwiperConfigInterface;
  public currentIndex: any = 1;
  private _bulletTextTranslations: string []

  constructor(private readonly _translate: TranslateService) {}

  ngOnInit(): void {
    this._translate.get(['']).subscribe(translations => {
      this._bulletTextTranslations = [
        this._translate.instant('lets_play__bullet-desc1'),
        this._translate.instant('lets_play__bullet-desc2'),
        this._translate.instant('lets_play__bullet-desc3'),
        this._translate.instant('lets_play__bullet-desc4')
      ];
      setTimeout(() => { this._initSwipper(); });
    });
  }

  public onResize(): void{
    this._initSwipper();
  }

  onIndexChange(event) {
    this.currentIndex = event + 1;
  }

  private _initSwipper(): SwiperConfigInterface {
    let bulletTextTranslations = this._bulletTextTranslations;
    return this.swipperConfig = {
      slidesPerView: 1,
      spaceBetween: 10,
      pagination: {
        el: '.swiper-pagination.lets-play__pagination',
        clickable: true
      },
      navigation: {
        nextEl: ".skin__carousel-arrow--right.lets-play__arrow",
        prevEl: ".skin__carousel-arrow--left.lets-play__arrow",
      },
      breakpoints: {
        1366: {
          direction: 'vertical',
          pagination: {
            renderBullet: function (index, className) {
              return '<div class="' + className + ' d-flex align-items-center justify-content-between lets-play-pagination__container mb-8">' +
                      ' <p class="lets-play-pagination__description mr-5 typo-22-Q mb-0 text-left skin__text-shadow">' + bulletTextTranslations[index] + '</p>' +
                      ' <div class="lets-play-pagination__bullet-wrapper d-flex align-items-center justify-content-center">' +
                      '   <div class="lets-play-pagination__bullet">' +
                      '     <i class="lets-play-pagination__bullet-icon ico-50 icon-icon_check1--1"></i>' +
                      '   </div>'+
                      ' </div>' +
                      '</div>';
            }
          }
        }
      }
    };
  }    
  
}
