import { Component, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import { AnimalsService } from 'ts/services/animals.service';

@Component({
  selector: 'app-animal-detail',
  templateUrl: './animal-detail.component.html',
  styleUrls: ['./animal-detail.component.scss']
})
export class AnimalDetailComponent implements OnInit {
  
  @Input() animal: any;
  @Input() animalAdjacents: any;

  constructor(private _router: Router,
              public animalsService: AnimalsService) {}

  ngOnInit(): void {}

  public routeAdjacentAnimal(animalName: string) {
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this._router.navigate(['/meet-the-animal/'+ animalName]);
  }

}
