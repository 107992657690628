<div class="skin__card-hover-effect-wrapper download-app-card__image-spacer d-flex align-items-center justify-content-center animate__animated animate__zoomIn">
  <section class="skin__card-hover-effect download-app-card download-app-card--two d-flex flex-column align-items-center justify-content-center m-auto skin__round-40">
        <img class="download-app-card__desktop-card-image d-block mw-100" src="assets/images/download-app-card-desktop-2.png" alt="Download Multisurprise App"/>
        <div class="download-app-card__store-buttons-container d-flex align-items-center w-100 mt-4 mt-xxl-2">
            <a class="store-hover--opacity" href="{{'global__google_play_store_url' | translate}}">
                <img src="assets/images/googleplay.png" class="android_app">
                <!-- <i class="ico-45 icon-btn_app_1-1-1" title="Download at App Store"></i> -->
            </a>
            <a class="store-hover--opacity" href="{{'global__app_store_url' | translate}}">
                <img src="assets/images/appstore.png" class="apple_app">
                <!-- <i class="ico-45 icon-btn_app_2-1-1"  title="Download at Google Play Store"></i> -->
            </a> 
        </div>
    </section>
</div>  
