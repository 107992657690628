import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-animal-carousel',
  templateUrl: './animal-carousel.component.html',
  styleUrls: ['./animal-carousel.component.scss']
})
export class AnimalCarouselComponent implements OnInit {
  
  @Input() animal: any;
  @Input() imageType: any;

  constructor() {}

  ngOnInit(): void {}
  
}
