import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { appConfig } from 'ts/app.config';

@Injectable()
export class ITguard implements CanActivate {

public appConfig: typeof appConfig = appConfig;

  constructor(private _router: Router) {}

  public canActivate (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(appConfig.location != 'IT') return true;
      this._router.navigate(['/404'])
      return false;
  }
}