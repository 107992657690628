<div class="d-flex flex-column align-items-center">
    <ng-container *ngIf="imageType == 'glb'">
        <model-viewer class="animals-carousel__animal-3d-image mb2" src="assets/images/animals/{{animal.name}}/{{imageType}}.glb" camera-controls alt="{{animal.name}}"></model-viewer>
    </ng-container>
    <ng-container *ngIf="imageType == 'lollypop'">
        <img class="animals-carousel__animal-plain-image mb2" src="assets/images/animals/{{animal.name}}/slide.png" alt="{{animal.name}}">
    </ng-container>
    <div class="animals-carousel__descriptor d-flex flex-column align-items-center">
        <div class="animals-carousel__title-container d-flex flex-column align-items-center">
            <h3 class="animal-carousel__title mb-0 skin__text-shadow">{{'animal_' + animal.name +'_translated'  | translate}}</h3>
            <p class="animal-carousel__subtitle mt-1 mb-0 skin__text-shadow">{{'animal_' + animal.name +'__surname'  | translate}}</p>
        </div>
        <a [routerLink]="'/meet-the-animal/'+ animal.name" class="d-flex mt-3"><button class="animal-carousel__button skin__btn-opac-back px-3 py-2 ">{{'animal__meet_me' | translate}}</button></a>
    </div>
</div>
