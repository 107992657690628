import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ts/services/local-storage.service';
import { appConfig } from 'ts/app.config';

@Injectable({ providedIn: 'root' })
export class TranslationService {

  private _languageStorageKey: string = "Language";
  private readonly _appConfig: typeof appConfig = appConfig;

  constructor(private _translate: TranslateService,
              private _localStorageService: LocalStorageService) {
    this._initTranslation();
  }

  private _initTranslation(): void {
    this._translate.addLangs(this._appConfig.appLanguages);
    this._translate.setDefaultLang(this._appConfig.appLanguages[0]);

    if(this._localStorageService.checkExistItemStorage(this._languageStorageKey)) {
      this._translate.use(this._localStorageService.getItemStorageValue(this._languageStorageKey));
    } else {
      this._translate.use(this._appConfig.appLanguages[0]);
      this._localStorageService.setItemStorage(this._languageStorageKey, this._appConfig.appLanguages[0]);
    }
  }

  public changeListLanguage(): void {
    let currentLanguage: string = this._translate.currentLang,
        availableLanguages: string[] = this._translate.getLangs(),
        newLanguage: string;

    availableLanguages.forEach((language, i) => {
      if(language === currentLanguage) {
        if(i == availableLanguages.length - 1) newLanguage = availableLanguages[0];
        else newLanguage = availableLanguages[i+1];
      } 
    });
    
    this.setLanguage(newLanguage);
  }

  public setLanguage(newLanguage): void {
    this._translate.getTranslation(newLanguage);
    this._translate.use(newLanguage);
    this._localStorageService.setItemStorage(this._languageStorageKey, newLanguage);
  }

  public getCurrentLanguage(): string {
    return this._localStorageService.getItemStorageValue(this._languageStorageKey);
  }

}