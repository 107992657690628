<div class="animal-card__image-exterior-spacer--mycollection d-flex justify-content-center h-100">
    <section class="animal-card d-flex flex-column align-items-center position-relative skin__object-shadow--big">
        <button *ngIf="animal.value.obtained" class="animal-card__favourite-button">
            <img *ngIf="!animal.value.favourite" (click)="animalsService.setFavouriteAnimal(animal.value.name)" class="welcome-country__chupachups-logo" src="assets/images/Icon awesome-heart--off.svg" alt="Chupa Chups Logo"/>
            <img *ngIf="animal.value.favourite" (click)="animalsService.setFavouriteAnimal(animal.value.name)" class="welcome-country__chupachups-logo" src="assets/images/Icon awesome-heart--on.svg" alt="Chupa Chups Logo"/>
        </button>
        <div class="animal-card__animal-image-container animal-card__image-interior-spacer--mycollection position-relative d-block w-100">
            <img *ngIf="!animal.value.obtained" class="animal-card__animal-image animal-card__animal-image--loly-wrapper" src="assets/images/animals/others/mystery-animal.png">
            <img *ngIf="animal.value.obtained" class="animal-card__animal-image animal-card__animal-image--animal-basic-1" src="assets/images/animals/{{animal.value.name}}/basic.png" alt="{{animal.value.name}}">
            <img *ngIf="!animal.value.obtained" class="animal-card__animal-image animal-card__animal-image--animal-basic-2" [ngStyle]="!animal.value.obtained && {'opacity': '0', 'height': '0' }" src="assets/images/animals/{{animal.value.name}}/basic-raw.png" alt="{{animal.value.name}}">
            <img *ngIf="!animal.value.obtained" class="animal-card__animal-image animal-card__animal-image--loly-base" [ngStyle]="!animal.value.obtained && {'opacity': '0', 'height': '0' }" src="assets/images/animals/{{animal.value.name}}/loly-ball.png" alt="{{animal.value.name}}">
        </div>
        <div class="position-relative">
            <h2 class="animal-card__title text-col-lavender text-center">{{'animal_' + animal.value.name +'_translated'  | translate}}</h2>
            <i *ngIf="animal.value.obtained" class="animal-card__obtain-icon icon-icon_check1 position-absolute skin__text-shadow"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>
        </div>
        <p class="animal-card__subtitle text-col-lavender text-center" [ngClass]="(animal.value.obtained)?'animal-card__subtitle--mycollection-obtained':''">{{'animal_'+ animal.value.name + '__surname' | translate}}</p>
        <p class="animal-card__description text-col-dust text-center d-none d-md-block">{{'animal_'+ animal.value.name + '__desc3' | translate}}</p>
        <span class="mt-auto d-flex justify-content-center w-100"><button *ngIf="!animal.value.obtained" (click)="animalsService.setObtainedAnimal(animal.value.name)" class="animal-card__unlock skin__btn-white-back-opac-bord px-3 py-2 typo-15-B text-col-lavender">{{'animal__add_to_collection' | translate}}</button></span>
    </section>
</div>
