<div class="animal-card__image-exterior-spacer--meet-me justify-content-center d-flex h-100">
    <section class="animal-card d-flex flex-column align-items-center skin__object-shadow--big">
        <div class="animal-card__image-interior-spacer--meet-me position-relative d-block w-100">
            <img class="animal-card__animal-image animal-card__animal-image--lolypop" src="assets/images/animals/{{animal.value.name}}/lollypop.png" alt="{{animal.value.name}}">
        </div>
        <h2 class="animal-card__title text-col-lavender text-center">{{'animal_' + animal.value.name +'_translated'  | translate}}</h2>
        <p class="animal-card__subtitle text-col-lavender text-center">{{'animal_'+ animal.value.name + '__surname' | translate}}</p>
        <p class="animal-card__description text-col-dust text-center d-none d-md-block">{{'animal_'+ animal.value.name + '__desc3' | translate}}</p>
        <a [routerLink]="'/meet-the-animal/'+ animal.value.name" class="mt-auto"><button class="skin__btn-white-back-opac-bord px-7 py-2 typo-15-B">{{'animal__meet_me' | translate}}</button></a>
    </section>
</div>
