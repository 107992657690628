import { Injectable } from "@angular/core";

@Injectable()
export class FormService {

  private _emptyInput: boolean;
  private _wrongTextData: boolean;
  private _unacceptConditions: boolean;
  private _elementForm: HTMLElement;
  private readonly _formLength = {
    INPUT_MAX_LENGHT: 32,
    TEXTAREA_MAX_LENGHT: 120
  }
  
  public initFormInputLenght(form: HTMLElement): void {

    this._elementForm = form;
    let self: this = this;

    this._elementForm.querySelectorAll('input, textarea').forEach(function(el) {
      var input: any = $(el);

      input.attr('maxlength', self._defineInputLenght(input.attr('name')));
    });
  }
  
  public formValidation(): boolean {
    let self: this = this;
    this._initErrorValidatorValues();

    this._elementForm.querySelectorAll('input, textarea').forEach(function(el) {
      var input: any = $(el);
      input.attr('maxlength', self._defineInputLenght(input.attr('name'))); //fallback
      self._checkEmptyInput(input.val().length);  
      self._validatePattern(input.attr('type'), input.val());
      self._checkAcceptConditions(input.attr('name'), input.is(":checked"));
    });

    if(this._unacceptConditions || this._emptyInput || this._wrongTextData) {
      this._printErrorFormMessages();
      return false;
    }
    return true;
  }

  private _initErrorValidatorValues(): void {
    this._emptyInput = false;
    this._wrongTextData  = false;
    this._unacceptConditions  = false;
  }

  private _defineInputLenght(inputName: string): number {
    if(inputName == "message") return this._formLength.TEXTAREA_MAX_LENGHT
    return this._formLength.INPUT_MAX_LENGHT; 
  }

  private _checkEmptyInput(inputLenght: number): void {
    if(inputLenght == 0) this._emptyInput = true;
  }

  private _validatePattern(inputType: string, inputValue: string): void {
    let emailPattern = /^[a-zñÑ0-9._%+-]+@[a-zñÑ0-9.-]+\.[a-z]{2,4}$/,
        basicPattern = /^([a-zA-ZñÑ0-9-\s]+)$/;

    if(inputType == 'email') {
      if((!emailPattern.test(inputValue)) && (inputValue.length > 0))  this._wrongTextData = true;
    } else {
      if((!basicPattern.test(inputValue)) && (inputValue.length > 0)) this._wrongTextData = true;
    }
  }

  private _checkAcceptConditions(inputName: string, inputChecked: any): void {
    if((inputName == 'accept_legal_conditions') && (!inputChecked)) this._unacceptConditions = true;
  }

  private _printErrorFormMessages(): void {
    let _formSubmitMessages: string = "";
    if (this._unacceptConditions) _formSubmitMessages += "<div> Accept conditions </div>";
    if (this._emptyInput) _formSubmitMessages += "<div> All fields required </div>";
    if (this._wrongTextData) _formSubmitMessages += "<div> Data Patterns should be ok </div>";
    
    $(this._elementForm.querySelector('#form-messages')).empty();
    $(this._elementForm.querySelector('#form-messages')).append(_formSubmitMessages);
  }

}