import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'ts/services/translation/translation.service';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { appConfig } from 'ts/app.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public currentPage: string;

  public appConfig: typeof appConfig = appConfig;
  
  constructor(private _translationService: TranslationService,
              public router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((val:any) => {
      let url: string = window.location.href;
      if(url.includes("welcome")) this.currentPage = "welcome";
      if(url.includes("parental")) this.currentPage = "parental";
      else  this.currentPage = "";
    });

    /*this.router.events.subscribe((val:any) => {
      let url: string = window.location.href;
      if(url.includes(".it")) appConfig.location = "IT";
      if(url.includes(".RU")) appConfig.location = "RU";
      else appConfig.location = "global";
    });*/

  }
  
}
