import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-animal-accessories',
  templateUrl: './animal-accessories.component.html',
  styleUrls: ['../animal-accessories.component.scss']
})
export class AnimalAccessoriesComponent implements OnInit {
  
  @Input() animalName: any;

  public swipperConfig: SwiperConfigInterface;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => { this._initSwipper(); });
  }

  public accesoriesIterator(): Array<number> { 
    return Array(6); 
  }

  private _initSwipper(): SwiperConfigInterface {
    return this.swipperConfig = {
      slidesPerView: 1.2,
      centeredSlides: true,    
      loop: true,
      pagination: {
        el: '.swiper-pagination.animal-accessories__pagination',
      },
      breakpoints: {
        350:{
          slidesPerView: 1.3
        },
        380: {
          slidesPerView: 1.4
        },
        450: {
          slidesPerView: 1.6
        },
        490: {
          slidesPerView: 1.7
        },
        515: {
          slidesPerView: 1.8
        }
      }
    };
  }
  
}
