<div class="skin__card-hover-effect-wrapper download-app-card__image-spacer d-flex align-items-center justify-content-center">
    <section class="skin__card-hover-effect download-app-card download-app-card--one position-relative skin__round-40 skin__object-shadow--big">
        <img class="download-app-card__desktop-card-image d-none d-md-block mw-100" src="assets/images/download-app-card-desktop-1.png" alt="Download Multisurprise App"/>
        <div class="download-app-card__content d-flex flex-column align-items-center align-items-md-start">
            <img class="download-app-card__bee-image d-md-none" src="assets/images/download-app-bee.svg" alt="Download Multisurprise App"/>
            <h2 class="download-app-card__title text-center text-md-left">{{'download_app__title' | translate}}</h2>
            <button [routerLink]="['/download-app']" class="download-app-card__download-button skin__btn-white-back-opac-bord py-2">{{'global__download_app' | translate}}</button>
            <p class="download-app-card__description text-center text-md-left">{{'download_app__desc' | translate}}</p>
            <div class="download-app-card__store-buttons-container d-flex align-items-center">
                <a class="store-hover--swap app-store" href="{{'global__app_store_url' | translate}}">
                    <img src="assets/images/appstore.png" class="apple_app">
                    <!-- <i class="download-app-card__store-icon icon-btn_app_1-1" title="Download at App Store"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i>
                    <i class="download-app-card__store-icon icon-btn_app_1-2" title="Download at App Store"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i> -->
                </a>
                <a class="store-hover--swap android-store" href="{{'global__google_play_store_url' | translate}}">
                    <img src="assets/images/googleplay.png" class="android_app">
                    <!-- <i class="download-app-card__store-icon icon-btn_app_2-1" title="Download at Google Play Store"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span><span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span><span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span></i>
                    <i class="download-app-card__store-icon icon-btn_app_2-2" title="Download at Google Play Store"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span><span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span><span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span></i> -->
                </a> 
            </div>
        </div>
    </section>
</div>