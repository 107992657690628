import { Component, OnInit } from '@angular/core';
import { constants } from 'ts/constants';
import { AnimalsService } from 'ts/services/animals.service';

@Component({
  selector: 'app-mycollection-filter-count',
  templateUrl: './mycollection-filter-count.component.html',
  styleUrls: ['./mycollection-filter-count.component.scss']
})
export class MycollectionFilterCountComponent implements OnInit {

  public readonly constants: typeof constants = constants;
  public animalsObtained: number;
  public animalsMissing: number;
  public animalsFavourite: number;

  constructor(public animalsService: AnimalsService) {}

  ngOnInit(): void {
    this.animalsService.observableAnimals.subscribe((animals) => { 
      this.animalsObtained= this.animalsService.animalsFilterCounter(this.constants.ANIMALS_FILTER_OBTAINED);
      this.animalsMissing= this.animalsService.animalsFilterCounter(this.constants.ANIMALS_FILTER_MISSING);
      this.animalsFavourite= this.animalsService.animalsFilterCounter(this.constants.ANIMALS_FILTER_FAVOURITE);
   });
  }  
}
