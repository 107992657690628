import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-animal-accessory',
  templateUrl: './animal-accessory.component.html',
  styleUrls: ['../animal-accessories.component.scss']
})
export class AnimalAccessoryComponent implements OnInit {
  
  @Input() animalName: any;
  @Input() animalAccesoryIndex: any;

  constructor() {}

  ngOnInit(): void {}
  
}
