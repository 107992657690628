<section class="d-flex flex-column align-items-center">
    <h3 class="discover-animals__title text-center skin__text-shadow">{{'discover_animals__title' | translate}}</h3>
    <p class="discover-animals__description text-center text-col-fjordgreen">{{'discover_animals__description' | translate}}</p> 
    <div class="discover-animals__carousel-wrapper mt-lg-3 mb-5">
        <div class="discover-animals__carousel">
            <div #carousel class="discover-animals__slides-container">
                <div #carousel__slide *ngFor="let animal of animals | keyvalue; let i = index" [attr.data-index]="i" class="discover-animals__slide">
                    <app-animal-carousel [animal]=animal.value imageType="glb"></app-animal-carousel>
                </div>
            </div>
        </div>
        <button class="skin__carousel-arrow skin__carousel-arrow--left discover-animals__arrow" (click)="prevSlide()"><i class="icon-icon_arrow_left1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i></button>
        <button class="skin__carousel-arrow skin__carousel-arrow--right discover-animals__arrow" (click)="nextSlide()"><i class="icon-icon_arrow_right1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i></button>
    </div>
    <a [routerLink]="['/meet-the-lovelyanimals']" class="discover-animals__meet-wrapper"><button class="skin__btn-white-back-opac-bord px-7 py-2 typo-15-B">{{'animal__meet_more_friends' | translate}}</button></a>
</section>