import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { constants } from 'ts/constants';
import { AnimalsService } from 'ts/services/animals.service';

@Component({
  selector: 'app-animals-cards-list-mycollection',
  templateUrl: './animals-cards-list-mycollection.component.html',
  styleUrls: ['../animals-cards.component.scss'],
})
export class AnimalsCardsListMycollectionComponent implements OnInit {

  @Input() animalCardType: any;

  public readonly constants: typeof constants = constants;
  public animals: any;
  public animalsObtained: number;
  public animalsMissing: number;
  public animalsFavourite: number;
  public activeFilter: any;


  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  constructor(private _animalsService: AnimalsService) {}

  ngOnInit(): void {
    this._animalsService.observableAnimalsFiltered.subscribe((animals) => { 
      this.animals = animals;
      this.activeFilter = this._animalsService.activeFilter;
    });
    this._animalsService.observableAnimals.subscribe((animals) => { 
      this.animalsObtained= this._animalsService.animalsFilterCounter(this.constants.ANIMALS_FILTER_OBTAINED);
      this.animalsMissing= this._animalsService.animalsFilterCounter(this.constants.ANIMALS_FILTER_MISSING);
      this.animalsFavourite = this._animalsService.animalsFilterCounter(this.constants.ANIMALS_FILTER_FAVOURITE);
    });
    this._animalsService.observableActiveFilter.subscribe((filter) => { 
      this.activeFilter = filter;
    });

  }

  isVisibleMyCollectionAnimals(): boolean {
    if ((this.activeFilter === this.constants.ANIMALS_FILTER_FAVOURITE) && (this.animalsFavourite == 0)) return false;
    if ((this.activeFilter === this.constants.ANIMALS_FILTER_OBTAINED) && (this.animalsObtained == 0)) return false;
    if ((this.activeFilter === this.constants.ANIMALS_FILTER_MISSING) && (this.animalsMissing == 0)) return false;
    return true;
  }

}
