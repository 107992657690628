import { Injectable } from "@angular/core";
import * as animalsCarousel from 'assets/responses/response-animals-carousel.json';
import * as animals from 'assets/responses/response-animals.json';
import { BehaviorSubject } from 'rxjs';
import { constants } from 'ts/constants';

@Injectable({ providedIn: 'root' })
export class AnimalsService {

  private readonly _constants: typeof constants = constants;
  public animals: Array<any>;
  public animalsCarousel: Array<any>;
  public observableAnimals: BehaviorSubject<Array<any>>;
  public observableAnimalsFiltered: BehaviorSubject<Array<any>>;
  public observableActiveFilter: BehaviorSubject<String>;
  public activeFilter: string = "all";

  constructor() {
    this.animalsCarousel = animalsCarousel.animals as Array<any>;
    this.animals = animals.animals as Array<any>;
    this.observableAnimals= new BehaviorSubject<Array<any>>(this.animals);
    this.observableAnimalsFiltered= new BehaviorSubject<Array<any>>(this.animals);
    this.observableActiveFilter = new BehaviorSubject<String>(this.activeFilter);
  }
  
  public setObtainedAnimal(animalName: string): void {
    let index: number = 0;
    for (var animal of this.animals) {
      if (animal.name === animalName) {
        if(this.animals[index].obtained) {
          this.animals[index].favourite = false;
        }
        this.animals[index].obtained = !animal.obtained;
        break;
      }
      index++;
    }
    this.observableAnimals.next(this.animals);
  }

  public setFavouriteAnimal(animalName: string): void {
    let index: number = 0;
    for (var animal of this.animals) {
      if (animal.name === animalName) {
        this.animals[index].favourite = !animal.favourite;
        break;
      }
      index++;
    }
    this.observableAnimals.next(this.animals);
  }

  public animalsFilterCounter(countType: string): number {
    let count: number = 0;
    for (var animal of this.animals) {
      if ((countType === this._constants.ANIMALS_FILTER_OBTAINED) && (animal.obtained)) count++;
      if ((countType === this._constants.ANIMALS_FILTER_MISSING) && (!animal.obtained)) count++;
      if ((countType === this._constants.ANIMALS_FILTER_FAVOURITE) && (animal.favourite)) count++;
    }
    return count;
  }

  public animalsFilterResults(filterType: string): void {
    if(this.activeFilter != filterType) {
        if (filterType === this._constants.ANIMALS_FILTER_OBTAINED) {
          this.observableAnimalsFiltered.next(this.animals.filter(animal => animal.obtained == true));
        } 
        else if (filterType === this._constants.ANIMALS_FILTER_MISSING) {
          this.observableAnimalsFiltered.next(this.animals.filter(animal => !animal.obtained));
        } 
        else {
          this.observableAnimalsFiltered.next(this.animals.filter(animal => animal.favourite));
        } 
        this.activeFilter = filterType;
    } else {
      this.observableAnimalsFiltered.next(this.animals);
      this.activeFilter = "all";
    }
    this.observableActiveFilter.next(this.activeFilter);
  }

}