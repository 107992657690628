import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { AnimalsService } from 'ts/services/animals.service';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-mylovelyanimals',
  templateUrl: './mylovelyanimals.component.html',
  styleUrls: ['./mylovelyanimals.component.scss'],
})
export class MylovelyanimalsComponent implements OnInit {
  
  private _animals: any;
  public swipperConfig: SwiperConfigInterface;
  public animalsGroups: any;
  
  constructor(private _animalsService: AnimalsService,
              private ngZone: NgZone) {}

  ngOnInit(): void {
    this._animals = this._animalsService.animalsCarousel;
    this.animalsGroups = this._slidesAnimalsGroups();
    this.setMyLoveAnimalsSliderBackground(0);
    setTimeout(() => {
      this._initSwipper();
    });
  }

  private _slidesAnimalsGroups(): any[][] {
    const animalsValues = Object.values(this._animals);

    var chunks = [];
    for(var i = 0; i < animalsValues.length; i += 3) {
      chunks.push(animalsValues.slice(i, i+3));
    }

    return chunks;
  }
  
  private _initSwipper(): SwiperConfigInterface {
    return this.swipperConfig = {
      slidesPerView: 1,
      loop: true,
      navigation: {
          nextEl: ".skin__carousel-arrow--left.mylovelyanimals__arrow",
          prevEl: ".skin__carousel-arrow--right.mylovelyanimals__arrow"
      },
      //fadeEffect: { crossFade: true },
      speed: 800,
      //effect: "fade",
      pagination: {
        el: '.swiper-pagination.mylovelyanimals__pagination',
        clickable: true
      },
    };
  }

  public setMyLoveAnimalsSliderBackground(index: number) {
    if (!$(".mylovelyanimals-slider-background-wrapper").hasClass("mylovelyanimals-carousel")) $(".mylovelyanimals-slider-background-wrapper").addClass("mylovelyanimals-carousel");
    $('.mylovelyanimals-slider-background-wrapper').removeClass(function (index, css) {
      return (css.match (/\bmylovelyanimals__carousel__background--type-\S+/g) || []).join(' ');
    });
    $(".mylovelyanimals-slider-background-wrapper").addClass("mylovelyanimals__carousel__background--type-" + index);
  }

  nextSlideEffect() {
    //$(".mylovelyanimals__carousel .swiper-slide").removeClass( "animate__animated animate__fadeInTopRight animate__fadeInTopLeft");
    //$(".mylovelyanimals__carousel .swiper-slide-prev").addClass( "animate__animated animate__fadeInTopRight" );
  }

  prevSlideEffect() {
    //$(".mylovelyanimals__carousel .swiper-slide").removeClass( "animate__animated animate__fadeInTopRight animate__fadeInTopLeft");
    //$(".mylovelyanimals__carousel .swiper-slide-next").addClass( "animate__animated animate__fadeInTopLeft" );
  }


}
