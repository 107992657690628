import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { ContactModel } from "../models/contact.model";

@Injectable()
export class ContactService {

  private _contactURL = "";

  constructor(private _http: HttpClient) {}

  public contact(contact: ContactModel) {
    return this._http.post(this._contactURL, contact);
  }
  
}