import { Component, OnInit } from '@angular/core';
import { appConfig } from 'ts/app.config';
import { TranslationService } from 'ts/services/translation/translation.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    '(window:resize)': 'onResize()',
  }
})
export class HeaderComponent implements OnInit {
  
  constructor(public translationService: TranslationService) {}
  public appConfig: typeof appConfig = appConfig;

  ngOnInit(): void {}

  public openHeaderMobileMenu(): void {
    $("#header__navigation,"+
      "#header__mylovelyanimals-image,"+
      "#header__language-config,"+
      "#header__close").removeClass("header--mobile-menu-display");

    $("#header__open").addClass("header--mobile-menu-display");
    $("#main-content").hide();
    $("#header").addClass("header--mobile-menu-background");
    $("app-header").addClass("position-relative");
  }

  public closeHeaderMobileMenu(): void {
    $("#header__navigation,"+
      "#header__mylovelyanimals-image,"+
      "#header__language-config,"+
      "#header__close").addClass("header--mobile-menu-display");

      $("#header__open").removeClass("header--mobile-menu-display");
      $("#main-content").show();
      $("#header").removeClass("header--mobile-menu-background");
      $("app-header").removeClass("position-relative");
  }

  public onResize(): void{
    this.closeHeaderMobileMenu();
  }

}
