import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';

/* Components */

import { FooterComponent } from './partials//footer/footer.component';
import { HeaderComponent } from './partials/header/header.component';
import { MycollectionCardComponent } from './partials/mycollection-card/mycollection-card.component';
import { DownloadAppCardOneComponent } from './partials/download-app-cards/download-app-card-one/download-app-card-one.component';
import { DownloadAppCardTwoComponent } from './partials/download-app-cards/download-app-card-two/download-app-card-two.component';
import { AnimalCarouselComponent } from './partials/animal-carousel/animal-carousel/animal-carousel.component';
import { DiscoverAnimalsComponent } from './partials/animal-carousel/discover-animals/discover-animals.component';
import { MylovelyanimalsComponent } from './partials//animal-carousel/mylovelyanimals/mylovelyanimals.component';
import { MycollectionFilterCountComponent } from './partials/mycollection-filter-count/mycollection-filter-count.component';
import { AnimalAccessoriesComponent } from './partials/animal-detail/animal-accessories/animal-accessories/animal-accessories.component';
import { AnimalAccessoryComponent } from './partials//animal-detail/animal-accessories/animal-accessory/animal-accessory.component';
import { AnimalDetailComponent } from './partials/animal-detail//animal-detail/animal-detail.component';
import { LetsPlayComponent } from './partials/lets-play/lets-play.component';
import { ContactFormComponent } from './partials/contact-form/contact-form.component';
import { FaqsQuestsComponent } from './partials/faqs-quests/faqs-quests.component';
import { LogoHeaderComponent } from './partials/logo-header/logo-header.component';
import { AnimalCardMycollectionComponent } from './partials/animals-cards/animal-card-mycollection/animal-card-mycollection.component';
import { AnimalCardMeetmeComponent } from './partials/animals-cards/animal-card-meetme/animal-card-meetme.component';
import { MycollectionHeaderComponent } from './partials/mycollection-header/mycollection-header.component';
import { AnimalsCardsListMeetmeComponent } from './partials/animals-cards/animals-cards-list-meetme/animals-cards-list-meetme.component';
import { AnimalsCardsListMycollectionComponent } from './partials/animals-cards/animals-cards-list-mycollection/animals-cards-list-mycollection.component';
import { ModalAboutComponent } from './partials/modal-about/modal-about.component';

/* Pages */

import { HomeComponent } from './pages/home/home.component';
import { DownloadAppComponent } from './pages/download-app/download-app.component';
import { MeetTheLovelyanimalsComponent } from './pages/meet-the-lovelyanimals/meet-the-lovelyanimals.component';
import { MeetTheAnimalComponent } from './pages/meet-the-animal/meet-the-animal.component';
import { MycollectionComponent } from './pages/mycollection/mycollection.component';
import { ContactComponent } from './pages/contact/contact.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { WelcomeCountryComponent } from './pages/welcome-country/welcome-country.component';

/* External Libraries */

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import {NgxPrintModule} from 'ngx-print';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/* Services */

import { AnimalsService } from 'ts/services/animals.service';
import { ContactService } from 'ts/services/contact.service';
import { TranslationService } from 'ts/services/translation/translation.service';
import { LocalStorageService } from 'ts/services/local-storage.service';
import { ModalService } from 'ts/services/modal.service';
import { ITguard } from 'ts/services/it-guard.service';
import { ParentalComponent } from './pages/parental/parental.component';
import { PoliticaPrivacidadComponent } from './pages/politica-privacidad/politica-privacidad.component';
import { CookiePrivacyComponent } from './pages/cookie-privacy/cookie-privacy.component';
import { LegalNoticeComponent } from './pages/legal-notice/legal-notice.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    MycollectionCardComponent,
    DownloadAppCardOneComponent,
    DownloadAppCardTwoComponent,
    DiscoverAnimalsComponent,
    HeaderComponent,
    MylovelyanimalsComponent,
    AnimalAccessoriesComponent,
    AnimalAccessoryComponent,
    AnimalDetailComponent,
    LetsPlayComponent,
    HomeComponent,
    AnimalCarouselComponent,
    DownloadAppComponent,
    MeetTheLovelyanimalsComponent,
    MeetTheAnimalComponent,
    MycollectionComponent,
    MycollectionFilterCountComponent,
    ContactFormComponent,
    ContactComponent,
    NotFoundComponent,
    FaqsComponent,
    LogoHeaderComponent,
    AnimalCardMycollectionComponent,
    AnimalCardMeetmeComponent,
    MycollectionHeaderComponent,
    AnimalsCardsListMycollectionComponent,
    AnimalsCardsListMeetmeComponent,
    ModalAboutComponent,
    FaqsQuestsComponent,
    WelcomeCountryComponent,
    ParentalComponent,
    PoliticaPrivacidadComponent,
    CookiePrivacyComponent,
    LegalNoticeComponent
  ],
  imports: [
    NgxPrintModule,
    BrowserModule,
    AppRoutingModule,
    SwiperModule,
    HttpClientModule,
    NgbModule,
    NgSelectModule, 
    FormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    AnimalsService,
    ContactService,
    TranslateService,
    TranslationService,
    LocalStorageService,
    ModalService,
    ITguard
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
