<section class="logo-header d-flex align-items-center justify-content-center">
    <h1 class="mb-0">
        <a [routerLink]="['/home']">
            <img *ngIf="logoType == 'solo'" class="logo-header__logo" src="assets/images/mylovelyanimals.svg" alt="My Lovely Animals"/>
            <img *ngIf="logoType == 'text'" class="logo-header__logo" src="assets/images/mylovelyanimals-text.svg" alt="My Lovely Animals"/>
        </a>
        <p *ngIf="logoType == 'solo'" style="font-size: 11px; text-align: center; margin-top: 5px;">*Мои Милые Животные</p>
        <p *ngIf="logoType == 'text'" style="font-size: 11px; text-align: center; margin-top: 5px;">*Мои Милые Животные. Собери все 12</p>
    </h1>
    
</section>
