import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
declare const cardHover: any;


@Component({
  selector: 'app-mycollection-card',
  templateUrl: './mycollection-card.component.html',
  styleUrls: ['./mycollection-card.component.scss']
})
export class MycollectionCardComponent implements OnInit, AfterViewInit {
  
  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {}

  ngAfterViewInit(){
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        cardHover(".mycollection-card");
      });
    });
 }

}
