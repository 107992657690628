<section class="lets-play">
    <h2 class="lets-play__subtitle text-center skin__text-shadow">{{'lets_play__subtitle' | translate}}</h2>
    <div class="lets-play__main-container d-xxl-flex align-items-xxl-center position-relative">
        <div class="lets-play__descriptor-container mb-xxl-7 pr-xxl-5">
            <p class="lets_play__bullet-desc text-center skin__text-shadow">{{'lets_play__bullet-desc' + this.currentIndex | translate}}</p>
            <h3 class="lets_play__title text-center text-xxl-left skin__text-shadow">{{'lets_play__title' + this.currentIndex | translate}}</h3>
            <p class="lets_play__desc text-col-fjordgreen text-center text-xxl-left">{{'lets_play__desc' + this.currentIndex | translate}}</p>
        </div>
        <div class="lets-play__carousel-wrapper py-7 py-xxl-0 position-relative">
            <div class="lets-play__carousel d-flex align-items-center">
                <swiper></swiper>
                <div class="swiper-container" [swiper]="swipperConfig" (indexChange)="onIndexChange($event)">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide"><img src="assets/images/app-mobile–screen-1.png"></div>
                        <div class="swiper-slide"><img src="assets/images/app-mobile–screen-2.png"></div>
                        <div class="swiper-slide"><img src="assets/images/app-mobile–screen-3.png"></div>
                        <div class="swiper-slide"><img src="assets/images/app-mobile–screen-4.png"></div>
                    </div>
                    <div class="swiper-pagination skin__carousel-pagination lets-play__pagination"></div>
                </div>
                <button class="skin__carousel-arrow skin__carousel-arrow--left lets-play__arrow d-xxl-none"><i class="icon-icon_arrow_left1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i></button>
                <button class="skin__carousel-arrow skin__carousel-arrow--right lets-play__arrow d-xxl-none"><i class="icon-icon_arrow_right1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i></button> 
            </div>
        </div>
    </div>
    <div class="lets-play__store-buttons-container d-flex align-items-center justify-content-center">
        <a class="store-hover--swap" href="{{'global__app_store_url' | translate}}">
            <img src="assets/images/appstore.png" class="apple_app">
            <!-- <i class="lets-play__store icon-btn_app_1-2-1" title="Download at App Store"><span class="path1"></span><span class="path2"></span></i>
            <i class="lets-play__store icon-btn_app_1-2-2" title="Download at App Store"><span class="path1"></span><span class="path2"></span></i> -->
        </a>
        <a class="store-hover--swap" href="{{'global__google_play_store_url' | translate}}">
            <img src="assets/images/googleplay.png" class="android_app">
<!--             <i class="lets-play__store icon-btn_app_2-2-1" title="Download at Google Play Store"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span><span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span><span class="path25"></span><span class="path26"></span><span class="path27"></span></i>
            <i class="lets-play__store icon-btn_app_2-2-2" title="Download at Google Play Store"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span><span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span><span class="path25"></span><span class="path26"></span><span class="path27"></span></i> -->
        </a>
    </div>
</section>
