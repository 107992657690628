<footer class="footer">
    <section class="footer__main px-4 pb-4 pb-lg-7">
        <div class="footer__main-container d-flex flex-column flex-lg-row justify-content-lg-between mx-lg-auto position-relative">
            <img class="footer__logo position-absolute" src="assets/images/chupachups-logo.svg" alt="Chupa Chups Logo"/>
            <p class="footer__description mb-5 mb-lg-0 typo-14-Q">{{'footer__desc' | translate}}</p>
            <div class="footer__navigation-wrapper d-flex flex-column align-items-lg-end justify-content-between">
                <ul class="footer__navigation">
                    <li class="mt-n2 mt-lg-0"><a [routerLink]="['/download-app']" class="py-2 pt-lg-0 typo-16-B skin__text-shadow">{{'global__download_app' | translate}}</a></li>
                    <li ><a [routerLink]="['/meet-the-lovelyanimals']" class="py-2 pt-lg-0 typo-16-B skin__text-shadow">{{'global__meet_the_lovely_animals' | translate}}</a></li>
                    <li><a [routerLink]="['/mycollection']" class="py-2 pt-lg-0 typo-16-B skin__text-shadow">{{'global__mycollection' | translate}}</a></li>
                    <!-- <li *ngIf="appConfig.location != 'IT'"><a [routerLink]="['/contact']" class="py-2 pt-lg-0 typo-16-B skin__text-shadow">{{'global__contact' | translate}}</a></li> -->
                    <li class="mb-n2 mb-lg-0"><a [routerLink]="['/faqs']" class="py-2 pt-lg-0 typo-16-B skin__text-shadow">{{'global__faqs' | translate}}</a></li>
                </ul>
                <p class="footer__contact-desc mt-4 mt-lg-0 mb-0 typo-13-Q" [innerHTML]="'footer__it-contact' | translate"></p>
            </div>

        </div>
    </section>
    <section class="footer__secondary p-4 px-lg-4 py-md-3">
        <div class="footer__secondary-container d-md-flex mx-auto">
            <div class="d-md-flex flex-md-grow-1 pr-md-2">
                <ul class="d-md-flex pl-0 mb-md-0">
                    <!-- <li (click)="modalService.openModal(constants.MODAL_ABOUT_US)" class="footer_secondary-link mt-n3 mt-md-0 ml-md-n4"><a class="typo-13-Q py-3 py-md-0 px-md-4">{{'global__about_us' | translate}}</a></li> -->
                    <li (click)="modalService.openModal(constants.MODAL_PRIVACY_POLICY)" class="footer_secondary-link"><a class="typo-13-Q py-3 py-md-0 px-md-4">{{'global__privacy_policy' | translate}}</a></li>
                    <li (click)="modalService.openModal(constants.MODAL_COOKIES_POLICY)" class="footer_secondary-link"><a class="typo-13-Q py-3 py-md-0 px-md-4">{{'global__cookies_policy' | translate}}</a></li>
                    <li (click)="modalService.openModal(constants.MODAL_LEGAL_NOTICE)" class="footer_secondary-link"><a class="typo-13-Q py-3 py-md-0 px-md-4">{{'global__legal_notice' | translate}}</a></li>
                </ul>
            </div>
            <p class="mb-0 text-right typo-13-Q">{{'footer__year' | translate}}</p>
        </div>
    </section>
</footer>
<div id="cookie_menu">
    <a href="javascript: Cookiebot.renew()" style="color:white !important; text-decoration: none !important;">
        <img src="assets/images/cookie-solid.svg" style="width: 32px; height: 32px; color:white !important;">
        <span [innerHTML]="'Cookie_gestion_label' | translate"></span>
    </a>
</div>