import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AnimalsService } from 'ts/services/animals.service';

@Component({
  selector: 'app-animals-cards-list-meetme',
  templateUrl: './animals-cards-list-meetme.component.html',
  styleUrls: ['../animals-cards.component.scss']
})
export class AnimalsCardsListMeetmeComponent implements OnInit {

  @Input() animalCardType: any;

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  public animals: any;

  constructor(private _animalsService: AnimalsService) {}

  ngOnInit(): void {
    this.animals = this._animalsService.animals;
  }

}
