import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { appConfig } from 'ts/app.config';
import { TranslationService } from 'ts/services/translation/translation.service';
import { ModalService } from 'ts/services/modal.service';
import { constants } from 'ts/constants';

@Component({
  selector: 'app-parental',
  templateUrl: './parental.component.html',
  styleUrls: ['./parental.component.scss']
})
export class ParentalComponent implements OnInit {

  public readonly constants: typeof constants = constants;
  public appConfig: typeof appConfig = appConfig;


  constructor(public translationService: TranslationService,
    private _translate: TranslateService,public modalService: ModalService) { }

  ngOnInit(): void {
  }

}
