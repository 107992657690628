<div class="mylovelyanimals-slider-background-wrapper home-slider skin__card-hover-effect">
    <div class="">
        <app-logo-header class="mb-0" logoType="text"></app-logo-header>
        <section class="d-flex flex-column align-items-center">
            <div class="mylovelyanimals__carousel mb-5 position-relative">
                <swiper></swiper>
                <div class="swiper-container" [swiper]="swipperConfig" (indexChange)="setMyLoveAnimalsSliderBackground($event)">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide d-flex p-0" *ngFor="let animalsGroup of animalsGroups">
                            <app-animal-carousel [animal] = animalsGroup[0] imageType = "lollypop"></app-animal-carousel>
                            <app-animal-carousel [animal] = animalsGroup[1] imageType = "lollypop"></app-animal-carousel>
                            <app-animal-carousel [animal] = animalsGroup[2] imageType = "lollypop"></app-animal-carousel>
                        </div>
                    </div>
                    <div class="swiper-pagination skin__carousel-pagination mylovelyanimals__pagination d-md-none mt-3"></div>
                </div>

            </div>
            <a [routerLink]="['/meet-the-lovelyanimals']"><button class="skin__btn-white-back-opac-bord px-7 py-2 typo-15-B">{{'animal__meet_more_friends' | translate}}</button></a>
        </section>
    </div>
</div>
<button class="skin__carousel-arrow skin__carousel-arrow--left mylovelyanimals__arrow d-none d-md-inline-block" (click)="prevSlideEffect()"><i class="icon-icon_arrow_left1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i></button>
<button class="skin__carousel-arrow skin__carousel-arrow--right mylovelyanimals__arrow d-none d-md-inline-block" (click)="nextSlideEffect()"><i class="icon-icon_arrow_right1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i></button>