import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faqs-quests',
  templateUrl: './faqs-quests.component.html',
  styleUrls: ['./faqs-quests.component.scss']
})
export class FaqsQuestsComponent implements OnInit {

  public col1: number;
  public col2: number;
  private _totalFaqs: number = 15;

  constructor() {}

  ngOnInit(): void {
    this._initCols();
  }
  
  private _initCols(): void {
    if((this._totalFaqs % 2) == 1) {
      this.col1 =  ((this._totalFaqs - 1) / 2) + 1;
      this.col2 =  ((this._totalFaqs - 1) / 2);
    } else {
      this.col1 =  this._totalFaqs / 2;
      this.col2 =  this._totalFaqs / 2;
    }
  }

  public faqsIterator(col: number): Array<number> { 
    return Array(col); 
  }

}
