<header id="header" class="d-flex flex-column flex-xxl-row-reverse align-items-xxl-center justify-content-xxl-center px-5 pt-3">
    <div class="d-flex align-items-center justify-content-between">
        <ul class=" d-xxl-none">
            <li id="header__open" (click)="openHeaderMobileMenu()"><i class="icon-icon_burger ico-24"></i></li>
            <li id="header__close" (click)="closeHeaderMobileMenu()" class="header--mobile-menu-display"><i class="icon-icon_close_burger ico-24"></i></li>
        </ul>
        <ul class=" d-flex align-items-center ">
            <li id="header__language-config" class="header--mobile-menu-display d-xxl-inline-block">
                <button (click)="translationService.changeListLanguage()" class="d-flex align-items-center">
                    <span class="header__current-language typo-12-Q">{{translationService.getCurrentLanguage()}}</span>
                    <div class="header__separator skin__separator"></div>
                    <i class="icon-icon_languaje1 ico-24"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></i>
                </button>
            </li>
        </ul>
    </div>
    <app-logo-header (click)="closeHeaderMobileMenu()" id="header__mylovelyanimals-image" class="header__mobile-logo header--mobile-menu-display d-xxl-none mb-7" logoType="text"></app-logo-header>
    <nav id="header__navigation" class="header__navigation header--mobile-menu-display d-xxl-inline-block mx-xxl-4">
        <ul class="header__links-container d-xxl-flex align-xxl-items-center ">
            <li class="header__navigation-item mt-n4 mt-xxl-0"><a (click)="closeHeaderMobileMenu()" [routerLink]="['/home']" routerLinkActive="is-active" class="typo-16-B py-4 py-xxl-0 skin__text-shadow d-flex align-items-center justify-content-between">{{'global__home' | translate}}<i class="icon-icon_arrow_mov ico-24 d-xxl-none"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i></a></li>
            <li class="header__navigation-item"><a (click)="closeHeaderMobileMenu()" [routerLink]="['/download-app']" routerLinkActive="is-active" class="typo-16-B py-4 py-xxl-0 skin__text-shadow d-flex align-items-center justify-content-between">{{'global__download_app' | translate}}<i class="icon-icon_arrow_mov ico-24 d-xxl-none"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i></a></li>
            <li class="header__navigation-item"><a (click)="closeHeaderMobileMenu()" [routerLink]="['/meet-the-lovelyanimals']" routerLinkActive="is-active" class="typo-16-B py-4 py-xxl-0 skin__text-shadow d-flex align-items-center justify-content-between">{{'global__meet_the_lovely_animals' | translate}}<i class="icon-icon_arrow_mov ico-24 d-xxl-none"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i></a></li>
            <li class="header__navigation-item"><a (click)="closeHeaderMobileMenu()" [routerLink]="['/mycollection']"  routerLinkActive="is-active" class="typo-16-B py-4 py-xxl-0 skin__text-shadow d-flex align-items-center justify-content-between">{{'global__mycollection' | translate}}<i class="icon-icon_arrow_mov ico-24 d-xxl-none"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i></a></li>
            <li class="header__navigation-item"><a (click)="closeHeaderMobileMenu()" [routerLink]="['/faqs']" routerLinkActive="is-active" class="typo-16-B py-4 py-xxl-0 skin__text-shadow d-flex align-items-center justify-content-between">{{'global__faqs' | translate}}<i class="icon-icon_arrow_mov ico-24 d-xxl-none"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i></a></li>
            <li *ngIf="appConfig.location != 'IT'" class="header__navigation-item  mb-n4 mb-xxl-0"><a (click)="closeHeaderMobileMenu()" [routerLink]="['/contact']" routerLinkActive="is-active" class="typo-16-B py-4 py-xxl-0 skin__text-shadow d-flex align-items-center justify-content-between">{{'global__contact' | translate}}<i class="icon-icon_arrow_mov ico-24 d-xxl-none"><span class="path1"></span><span class="path2"></span><span class="path3"></span></i></a></li>
        </ul>
    </nav>
    <a href="/home"><img class="d-none d-xxl-inline-block" src="assets/images/chupachups-logo-with-text.svg" alt="Chupa Chups Logo"/></a>
</header>