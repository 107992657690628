import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mycollection',
  templateUrl: './mycollection.component.html',
  styleUrls: ['./mycollection.component.scss']
})
export class MycollectionComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {}

}
